import React from "react";

interface DotProps {
  width?: number;
  active?: boolean;
  onClick?: () => void;
}

const DotIcon: React.FC<DotProps> = ({ width, active, onClick }) => {
  let circle = active ? (
    <circle
      cx="50%"
      cy="50%"
      r="35%"
      fill="#D8D8D8"
      strokeWidth="2.5"
      fillOpacity="0"
      stroke="#1D4ED8"
    />
  ) : (
    <circle cx="50%" cy="50%" r="35%" fill="#D8D8D8" strokeWidth="2" />
  );
  return (
    <svg
      width={width || 18}
      height={width || 18}
      onClick={onClick}
      className="cursor-pointer"
    >
      {circle}
    </svg>
  );
};

export default DotIcon;
