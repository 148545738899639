import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import DotIcon from "./svgs/dot-icon";

import Button from "./button";
import { messages } from "../i18n-translations";

interface LargeServicesSliderProps {
  slides: ServiceSlideProps[];
  cn?: boolean;
}

const carouselResponsive = {
  desktop: {
    breakpoint: { max: 5000, min: 0 },
    items: 3,
    partialVisibilityGutter: 40,
  },
};

const LargeServicesSlider: React.FC<LargeServicesSliderProps> = ({
  slides,
  cn,
}) => {
  const lang = cn ? "cn" : "en";
  const CustomDot: React.FC<any> = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType },
    } = rest;
    const carouselItems = slides.map((slide, i) => {
      return <div key={i} className="w-full h-full px-1"></div>;
    });
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return <DotIcon active={active} onClick={onClick} width={20} />;
  };

  const CustomRightArrow: React.FC<any> = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <ChevronRightIcon
        onClick={() => onClick()}
        className={
          "w-16 h-16 absolute -right-5 text-gray-500 opacity-20 hover:opacity-100 cursor-pointer"
        }
      />
    );
  };

  const CustomLeftArrow: React.FC<any> = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <ChevronLeftIcon
        onClick={() => onClick()}
        className={
          "w-16 h-16 absolute -left-5 text-gray-500 opacity-20 hover:opacity-100 cursor-pointer"
        }
      />
    );
  };

  return (
    <div className="relative">
      <Carousel
        responsive={carouselResponsive}
        arrows={false}
        infinite
        autoPlay
        pauseOnHover
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        showDots
        renderDotsOutside
        customDot={<CustomDot />}
        renderButtonGroupOutside={false}
        dotListClass="-mb-10 flex gap-2"
      >
        {slides.map((slide, i) => {
          return (
            <div key={i} className="w-full h-full px-1">
              <div
                className={`flex flex-col items-center justify-center w-full h-full  py-8 mr-4 text-center border-2 border-blue-400 ${
                  cn ? "px-6" : "px-2"
                }`}
              >
                <div className="mb-6">{slide.image}</div>
                <div
                  className={`mb-6 text-xl font-semibold ${
                    cn ? "" : "lg:h-14"
                  }`}
                >
                  {slide.title}
                </div>
                <div className="mb-6">{slide.description}</div>
                <Button className="w-40 py-2 mt-auto text-white rounded-full">
                  {messages[lang].learnMore}
                </Button>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default LargeServicesSlider;
