import React from "react";

const RubberPlasticIcon: React.FC<IconProps> = ({ width }) => {
  return (
    <svg
      width={width || 50}
      viewBox="0 0 50 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-_19iia5ymh-1"
          points="0 44.9430531 49.2532468 44.9430531 49.2532468 0 0 0"
        ></polygon>
      </defs>
      <g
        id="Homepage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="jintai_website_mobile"
          transform="translate(-103.000000, -1519.000000)"
        >
          <g id="About" transform="translate(31.000000, 975.000000)">
            <g id="Group-20" transform="translate(72.000000, 522.000000)">
              <g id="Group-30" transform="translate(0.000000, 22.568575)">
                <path
                  d="M9.88586542,6.70854216 C6.75705292,6.70854216 4.2118914,4.0586116 4.2118914,0.801004651 L5.75105536,0.801004651 C5.75105536,3.1746604 7.6054401,5.10602005 9.88586542,5.10602005 C12.1656751,5.10602005 14.0206755,3.1746604 14.0206755,0.801004651 L15.5598394,0.801004651 C15.5598394,4.0586116 13.0140623,6.70854216 9.88586542,6.70854216"
                  id="Fill-2"
                  fill="#0469D5"
                ></path>
                <polygon
                  id="Fill-4"
                  fill="#0469D5"
                  points="21.3264711 23.46618 19.7873072 23.46618 19.7873072 15.1548593 9.11597561 15.1548593 9.11597561 5.9070247 10.6551396 5.9070247 10.6551396 13.5523372 21.3264711 13.5523372"
                ></polygon>
                <mask id="mask-_19iia5ymh-2" fill="white">
                  <use xlinkHref="#path-_19iia5ymh-1"></use>
                </mask>
                <g id="Clip-7"></g>
                <path
                  d="M19.3189704,26.8448094 L21.7945617,26.8448094 L21.7945617,24.2679538 L19.3189704,24.2679538 L19.3189704,26.8448094 Z M17.7798064,28.4473315 L23.3337256,28.4473315 L23.3337256,22.6654317 L17.7798064,22.6654317 L17.7798064,28.4473315 Z"
                  id="Fill-6"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <polygon
                  id="Fill-8"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="19.7874919 32.5626083 21.3266558 32.5626083 21.3266558 27.6460704 19.7874919 27.6460704"
                ></polygon>
                <path
                  d="M22.3975446,17.4197358 L21.5805563,16.0614381 C22.150047,15.690294 22.4898944,15.0518492 22.4898944,14.3537905 C22.4898944,13.2435632 21.6230373,12.3410228 20.5567045,12.3410228 C19.9164123,12.3410228 19.318601,12.6711423 18.9572053,13.2224099 L17.6852402,12.3211515 C18.333536,11.3301518 19.4066412,10.7385007 20.5567045,10.7385007 C22.4714244,10.7385007 24.0290584,12.360253 24.0290584,14.3537905 C24.0290584,15.6069628 23.4189338,16.7530866 22.3975446,17.4197358"
                  id="Fill-9"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M9.84917175,18.0363863 C7.93445179,18.0363863 6.37681786,16.414634 6.37681786,14.4210965 C6.37681786,13.1679242 6.98694245,12.0218004 8.00833166,11.3551512 L8.82531989,12.7134489 C8.25582922,13.084593 7.91598182,13.7230378 7.91598182,14.4210965 C7.91598182,15.5313238 8.78345463,16.4338642 9.84917175,16.4338642 C10.489464,16.4338642 11.0878909,16.1037447 11.4486709,15.5524771 L12.7212517,16.4537355 C12.0729558,17.4447352 10.9992351,18.0363863 9.84917175,18.0363863"
                  id="Fill-10"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M44.8862076,23.6395728 L43.1081654,23.6395728 L43.1081654,22.6780596 L44.8862076,22.6780596 C45.2359057,22.6780596 45.5203432,22.3819135 45.5203432,22.0184615 L45.5203432,8.23677134 C45.5203432,7.87203731 45.2359057,7.57589122 44.8862076,7.57589122 L43.1081654,7.57589122 L43.1081654,6.61437796 L44.8862076,6.61437796 C45.7450611,6.61437796 46.4438416,7.34192299 46.4438416,8.23677134 L46.4438416,22.0184615 C46.4438416,22.9120278 45.7450611,23.6395728 44.8862076,23.6395728"
                  id="Fill-11"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <polygon
                  id="Fill-12"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="35.7393872 1.60252211 46.7197829 1.60252211 46.7197829 0 35.7393872 0"
                ></polygon>
                <path
                  d="M38.6844235,1.60239391 L38.6844235,4.25296548 C38.6844235,5.71446564 39.8264832,6.90353705 41.2302007,6.90353705 C42.6339182,6.90353705 43.7753622,5.71446564 43.7753622,4.25296548 L43.7753622,1.60239391 L38.6844235,1.60239391 Z M41.2302007,8.50605915 C38.978096,8.50605915 37.1452595,6.59777583 37.1452595,4.25296548 L37.1452595,-0.000128201769 L45.3145262,-0.000128201769 L45.3145262,4.25296548 C45.3145262,6.59777583 43.4823054,8.50605915 41.2302007,8.50605915 L41.2302007,8.50605915 Z"
                  id="Fill-13"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M41.2300776,3.56356047 C40.8649879,3.56356047 40.5682371,3.87316774 40.5682371,4.25264497 C40.5682371,4.63276322 40.8649879,4.94172948 41.2300776,4.94172948 C41.5951672,4.94172948 41.8919181,4.63276322 41.8919181,4.25264497 C41.8919181,3.87316774 41.5951672,3.56356047 41.2300776,3.56356047 M41.2300776,5.90324274 C40.3558324,5.90324274 39.6447387,5.16287753 39.6447387,4.25264497 C39.6447387,3.34241241 40.3558324,2.6020472 41.2300776,2.6020472 C42.1043227,2.6020472 42.8154164,3.34241241 42.8154164,4.25264497 C42.8154164,5.16287753 42.1043227,5.90324274 41.2300776,5.90324274"
                  id="Fill-14"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M41.2300776,22.3005695 C40.7757164,22.3005695 40.4057013,22.6858158 40.4057013,23.1588803 C40.4057013,23.6319448 40.7757164,24.0171912 41.2300776,24.0171912 C41.6844388,24.0171912 42.0544538,23.6319448 42.0544538,23.1588803 C42.0544538,22.6858158 41.6844388,22.3005695 41.2300776,22.3005695 M41.2300776,25.6197133 C39.9267135,25.6197133 38.8665374,24.515896 38.8665374,23.1588803 C38.8665374,21.8018646 39.9267135,20.6980474 41.2300776,20.6980474 C42.5334416,20.6980474 43.5936177,21.8018646 43.5936177,23.1588803 C43.5936177,24.515896 42.5334416,25.6197133 41.2300776,25.6197133"
                  id="Fill-15"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M30.3110637,26.4032825 L32.225168,26.4032825 L32.225168,20.9117597 C32.225168,20.3617741 31.7960491,19.914991 31.2684237,19.914991 C30.7401826,19.914991 30.3110637,20.3617741 30.3110637,20.9117597 L30.3110637,26.4032825 Z M33.764332,28.0058046 L28.7718998,28.0058046 L28.7718998,20.9117597 C28.7718998,19.4784639 29.8917955,18.3124689 31.2684237,18.3124689 C32.6444363,18.3124689 33.764332,19.4784639 33.764332,20.9117597 L33.764332,28.0058046 Z"
                  id="Fill-16"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <polygon
                  id="Fill-17"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="36.779862 23.9602696 39.6359347 23.9602696 39.6359347 22.3577475 36.779862 22.3577475"
                ></polygon>
                <polygon
                  id="Fill-18"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="32.99475 23.9602696 35.850207 23.9602696 35.850207 22.3577475 32.99475 22.3577475"
                ></polygon>
                <polygon
                  id="Fill-19"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="40.5027918 21.4994366 42.0419558 21.4994366 42.0419558 18.40721 40.5027918 18.40721"
                ></polygon>
                <polygon
                  id="Fill-20"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="30.4988417 32.5626083 32.0380057 32.5626083 32.0380057 29.0594949 30.4988417 29.0594949"
                ></polygon>
                <polygon
                  id="Fill-21"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="40.5027918 18.8879666 42.0419558 18.8879666 42.0419558 16.4617481 40.5027918 16.4617481"
                ></polygon>
                <polygon
                  id="Fill-22"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="40.5027918 14.9438392 42.0419558 14.9438392 42.0419558 11.8522535 40.5027918 11.8522535"
                ></polygon>
                <polygon
                  id="Fill-23"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="40.5027918 10.5619027 42.0419558 10.5619027 42.0419558 7.70428529 40.5027918 7.70428529"
                ></polygon>
                <path
                  d="M4.01567877,40.2281766 C3.63150345,40.2281766 3.319361,40.5531681 3.319361,40.9531576 C3.319361,41.3531471 3.63150345,41.6787796 4.01567877,41.6787796 C4.3998541,41.6787796 4.71199655,41.3531471 4.71199655,40.9531576 C4.71199655,40.5531681 4.3998541,40.2281766 4.01567877,40.2281766 M4.01567877,42.6402929 C3.12234801,42.6402929 2.39586262,41.8832614 2.39586262,40.9531576 C2.39586262,40.0236948 3.12234801,39.2666633 4.01567877,39.2666633 C4.90900954,39.2666633 5.63549493,40.0236948 5.63549493,40.9531576 C5.63549493,41.8832614 4.90900954,42.6402929 4.01567877,42.6402929"
                  id="Fill-24"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M24.6266234,40.2281766 C24.2424481,40.2281766 23.9303056,40.5531681 23.9303056,40.9531576 C23.9303056,41.3531471 24.2424481,41.6787796 24.6266234,41.6787796 C25.0107987,41.6787796 25.3229412,41.3531471 25.3229412,40.9531576 C25.3229412,40.5531681 25.0107987,40.2281766 24.6266234,40.2281766 M24.6266234,42.6402929 C23.7332926,42.6402929 23.0068072,41.8832614 23.0068072,40.9531576 C23.0068072,40.0236948 23.7332926,39.2666633 24.6266234,39.2666633 C25.5199541,39.2666633 26.2464395,40.0236948 26.2464395,40.9531576 C26.2464395,41.8832614 25.5199541,42.6402929 24.6266234,42.6402929"
                  id="Fill-25"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M45.2376295,40.2281766 C44.8534542,40.2281766 44.5413118,40.5531681 44.5413118,40.9531576 C44.5413118,41.3531471 44.8534542,41.6787796 45.2376295,41.6787796 C45.6218049,41.6787796 45.9339473,41.3531471 45.9339473,40.9531576 C45.9339473,40.5531681 45.6218049,40.2281766 45.2376295,40.2281766 M45.2376295,42.6402929 C44.3442988,42.6402929 43.6178134,41.8832614 43.6178134,40.9531576 C43.6178134,40.0236948 44.3442988,39.2666633 45.2376295,39.2666633 C46.1309603,39.2666633 46.8574457,40.0236948 46.8574457,40.9531576 C46.8574457,41.8832614 46.1309603,42.6402929 45.2376295,42.6402929"
                  id="Fill-26"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <path
                  d="M3.83202573,38.5661689 C2.56744862,38.5661689 1.53928709,39.6366537 1.53928709,40.9532858 C1.53928709,42.269277 2.56744862,43.3404028 3.83202573,43.3404028 L45.4214673,43.3404028 C46.6854287,43.3404028 47.7142059,42.269277 47.7142059,40.9532858 C47.7142059,39.6366537 46.6854287,38.5661689 45.4214673,38.5661689 L3.83202573,38.5661689 Z M45.4214673,44.9429249 L3.83202573,44.9429249 C1.71906144,44.9429249 0.000123133117,43.1532282 0.000123133117,40.9532858 C0.000123133117,38.7533435 1.71906144,36.9636468 3.83202573,36.9636468 L45.4214673,36.9636468 C47.5344316,36.9636468 49.2533699,38.7533435 49.2533699,40.9532858 C49.2533699,43.1532282 47.5344316,44.9429249 45.4214673,44.9429249 L45.4214673,44.9429249 Z"
                  id="Fill-27"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                ></path>
                <polygon
                  id="Fill-28"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="7.00319602 41.4335296 21.4713373 41.4335296 21.4713373 40.4720164 7.00319602 40.4720164"
                ></polygon>
                <polygon
                  id="Fill-29"
                  fill="#0469D5"
                  mask="url(#mask-_19iia5ymh-2)"
                  points="27.7819095 41.4335296 42.2500507 41.4335296 42.2500507 40.4720164 27.7819095 40.4720164"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RubberPlasticIcon;
