import React, { useRef, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

import Button from "./button";
import DotIcon from "./svgs/dot-icon";
import { messages } from "../i18n-translations";

interface ServicesSliderProps {
  slides: ServiceSlideProps[];
  cn?: boolean;
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 400 : -400,
      opacity: 0,
      transition: { duration: 0.2 },
    };
  },
  center: {
    zIndex: 10,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 400 : -400,
      opacity: 0,
      transition: { duration: 0.2 },
    };
  },
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const ServicesSlider: React.FC<ServicesSliderProps> = ({ slides, cn }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [autoPlay, setAutoPlay] = useState<NodeJS.Timer>();
  const autoPlayNext = useRef<() => void>();
  const lang = cn ? "cn" : "en";

  const autoPlayTimer = 2000;
  const slideIndex = wrap(0, slides.length, page);

  autoPlayNext.current = () => paginate(1);

  useEffect(() => {
    const autoPlayInterval = setInterval(() => {
      if (autoPlayNext.current) {
        autoPlayNext.current();
      }
    }, autoPlayTimer);

    setAutoPlay(autoPlayInterval);

    return () => clearInterval(autoPlayInterval);
  }, []);

  const stopAutoPlay = () => {
    if (autoPlay) clearInterval(autoPlay);
  };

  const paginate = (newDirection: number) => {
    let direction = 1;
    if (slideIndex >= slides.length - 1) direction = -1;
    setPage([page + newDirection, direction]);
  };

  const goToSlide = (slideIndex: number) => {
    let direction = 1;
    if (slideIndex < page) direction = -1;
    setPage([slideIndex, direction]);
  };

  return (
    <div>
      <div
        className="flex flex-col w-full overflow-hidden bg-white"
        onClick={() => stopAutoPlay()}
      >
        <AnimatePresence custom={direction} exitBeforeEnter>
          {slides
            .filter((slide, index) => index === slideIndex)
            .map((slide, i) => {
              return (
                <motion.div
                  key={page}
                  className="flex flex-col items-center justify-center w-full min-w-full px-4 py-8 text-center"
                  custom={direction}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  style={{ height: 350 }}
                  transition={{
                    x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 },
                  }}
                  drag="x"
                  dragConstraints={{ left: 0, right: 0 }}
                  dragElastic={1}
                  onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x);

                    if (swipe < -swipeConfidenceThreshold) {
                      paginate(1);
                    } else if (swipe > swipeConfidenceThreshold) {
                      paginate(-1);
                    }
                  }}
                >
                  <div className="mb-6">{slide.image}</div>
                  <div className="mb-6 text-2xl">{slide.title}</div>
                  <div className="mb-6">{slide.description}</div>
                  <Button className="w-1/2 py-2 text-white rounded-full">
                    {messages[lang].learnMore}
                  </Button>
                </motion.div>
              );
            })}
        </AnimatePresence>
      </div>

      <div className="flex items-center justify-center mt-8">
        <div className="flex gap-3">
          {slides.map((slide, i) => {
            return i === slideIndex ? (
              <DotIcon active key={i} />
            ) : (
              <DotIcon
                key={i}
                onClick={() => {
                  goToSlide(i);
                  stopAutoPlay();
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServicesSlider;
