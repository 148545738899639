import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React from "react";
import { messages } from "../i18n-translations";
import LargeServicesSlider from "./large-services-slider";
import ServicesSlider from "./services-slider";
import RubberPlasticIcon from "./svgs/rubber-plastic-icon";

interface SolutionsProps {
  cn?: boolean;
}

const Solutions: React.FC<SolutionsProps> = ({ cn }) => {
  const breakpoints = useBreakpoint();
  const isScreenXl = breakpoints.xl;
  const isScreenMd = breakpoints.md;
  const lang = cn ? "cn" : "en";

  let servicesSlides = [
    {
      image: <RubberPlasticIcon width={50} />,
      title: messages[lang].precisionMachining,
      description: messages[lang].precisionMachiningDescription,
      buttonText: messages[lang].learnMore,
    },
    {
      image: <RubberPlasticIcon width={50} />,
      title: messages[lang].plasticInjectionMouldingAndRubberCompression,
      description:
        messages[lang].plasticInjectionMouldingAndRubberCompressionDescription,
      buttonText: messages[lang].learnMore,
    },
    {
      image: <RubberPlasticIcon width={50} />,
      title: messages[lang].vacuumBrazing,
      description: messages[lang].vacuumBrazingDescription,
      buttonText: messages[lang].learnMore,
    },
    {
      image: <RubberPlasticIcon width={50} />,
      title: messages[lang].castingSurfaceTreatmentSourcing,
      description: messages[lang].castingSurfaceTreatmentSourcingDescription,
      buttonText: messages[lang].learnMore,
    },
    {
      image: <RubberPlasticIcon width={50} />,
      title: messages[lang].assembly,
      description: messages[lang].assemblyDescription,
      buttonText: messages[lang].learnMore,
    },
  ];
  return (
    <div className="w-full mx-2 mb-8 lg:w-11/12 lg:mx-auto">
      {!isScreenXl && !isScreenMd ? (
        <ServicesSlider cn={cn} slides={servicesSlides} />
      ) : (
        <LargeServicesSlider cn={cn} slides={servicesSlides} />
      )}
    </div>
  );
};

export default Solutions;
